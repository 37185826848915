const faq = [
    {
        id: '1',
        q: 'What is ArtMonkees?',
        r: 'ArtMonkees is a collection of 10.000 3D ArtMonkees programmatically generated, concepted by Andre Holzmeister. Divided in 2 Collection',
    },
    {
        id: '2',
        q: 'Where can I buy ArtMonkees?',
        r: 'We are listed on: <a href="https://magiceden.io/creators/artmonkees" target="_blank">Magic Eden</a>, OpenSea and Solanart',
    },
    {
        id: '3',
        q: 'Where can I see my ArtMonkee NFT?',
        r: 'Direct in your Phantom wallet!',
    },
    {
        id: '4',
        q: 'Will there be sale royalties?',
        r: 'Royalties will be 7.5% of each sale and will help funding a community vault.',
    }
]
export default faq
