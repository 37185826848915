import React, { useState,} from "react";
import team from "../api/team";

function Team(props) {



    return (
        <div id='team' className="bg-white container-fluid py-5" >

            <h2 className='monkees text-center text-black'>The Team</h2>

            <div className='container text-center text-black mt-5 '>
                <div className="row row-cols-2 row-cols-md-3  row-cols-lg-4   row-cols-xl-6 cols-4 d-flex justify-content-center">
                { team.itens.map(item => (
                    <div className='col px-4 mb-3'>

                    <img className='img-fluid rounded-circle' src={item.img} />
                    <h5 className='mt-3'>{item.title}</h5>
                        <p>{item.discription}</p>
                    </div>

                ))}
                </div>
            </div>
        </div>
    );
}

export default Team;
