import React, { useState,} from "react";
import faq from "../api/faq";

function Faq(props) {


    return (

            <div id='faq' className="bg-light container-fluid py-5" >

                <h2 className='monkees text-center text-black'>FAQ</h2>


                <div className='d-flex justify-content-center mt-5'>
                    <div className='col-11 col-sm-10 col-lg-8'>

                        <div className="accordion accordion-flush bg-transparent" id="accordionFlushExample">

                            {faq.map(item => (

                                <div className="accordion-item border border-success rounded-5 mb-3">
                                    <h5 className="accordion-header" id={`flush-heading${item.id}`}>
                                        <button className="accordion-button border-0 rounded-5 collapsed bg-transparent"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target={`#flush-collapse${item.id}`} aria-expanded="false"
                                                aria-controls={`flush-collapse${item.id}`}>
                                            <h5>{item.q}</h5>
                                        </button>
                                    </h5>
                                    <div id={`flush-collapse${item.id}`} className="accordion-collapse collapse "
                                         aria-labelledby={`flush-heading${item.id}`} data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body rounded-5">
                                            {item.r}
                                        </div>
                                    </div>
                                </div>

                            ))}

                        </div>
                    </div>
                </div>

            </div>

    );
}


export default Faq;
