import React from "react";
import ImgTwitter from "../static/imgs/twitter.svg";


function BtTwitter() {

    function handleTwitter(e) {
        e.preventDefault();
        window.history.replaceState(null, "ArtMonkees", "#subscribe");
        window.open("https://twitter.com/artmonkees", "_blank");
    }


    return (


                <button className='btn btn-lg btn-dark px-4 me-3 rounded-5 border-0' onClick={handleTwitter}
                        style={{backgroundColor: '#00acee'}}><img className='align-middle me-2' src={ImgTwitter}
                                                                  height="30px"/>Twitter
                </button>


    );

}

export default BtTwitter;
