import React from "react";


import img_solanart from '../../static/imgs/logo_solanart.png'
import img_magiceden from '../../static/imgs/logo_magicedem.png'


function SecondaryMarket1() {


    return (
        <div className='bg-warning'>

            <div className='container'>


                <div className='d-flex justify-content-between align-items-center'>


                    <div className=' text-white my-4' >
                        <div className='ms-5'>
                            <div className='h4 text-white fw-bolder'>SOLD OUT!</div>
                            <p>Thanks Everyone! <br />
                                ArtMonkees First Collection sold out in only 4 minutes.  <br />
                                You still can get your Monkee on our official secondary market partners </p>

                        </div>
                    </div>

                    <div className='d-flex text-white text-center p-4 d-flex justify-content-center align-items-center'>
                        <a href="https://solanart.io/collections/artmonkees" target='_blank'><div className='me-0 me-lg-5 p-3 img-thumbnail'><img src={img_solanart} width={80} /></div></a>
                        <a href="https://magiceden.io/marketplace/artmonkees"  target='_blank'><div className='me-0 me-lg-5 p-3 img-thumbnail'><img src={img_magiceden} width={80} /></div></a>
                    </div>


                </div>



            </div>

        </div>
    );
}

export default SecondaryMarket1;
