import React, {useEffect, useState} from "react";
import imgMainBanner from '../static/imgs/gen2_gv_01.png';
import { useSpring, animated } from 'react-spring';
import img_bg_space from '../static/imgs/bg_space.jpg';
import Countdown, {calcTimeDelta, zeroPad} from 'react-countdown';
import moment from "moment";
import img_solana from '../static/imgs/solana.svg'
import img_monkee from '../static/imgs/monkees_icon_2.png'
import img_solanart from '../static/imgs/logo_solanart.png'
import img_magiceden from '../static/imgs/logo_magicedem.png'
import BtTwitter from "./BtTwitter";
import BtDiscord from "./BtDiscord";
import { Parallax } from 'react-parallax';

// Renderer callback with condition
const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return (


        <div className='mt-4 p-4 border-white border rounded-5' style={{backgroundColor: '#5c0ba1'}}>


            <h2 className='fw-bolder'>Sold Out! Thank You Everyone!!</h2>

            <h3><b>Mint with token only is live!</b></h3>

            <div className='text-center text-white' >
                <a className='btn btn-lg px-5 btn-outline-light' href='https://mint.artmonkees.io/'><h3 className='fw-bolder mt-2'>MINT WITH TOKEN!</h3></a>
            </div>
            {/*<div className='d-flex  justify-content-center align-items-center mt-4'><h4 className='mt-2 me-2'>0.8 </h4> <img src={img_solana} width={25} /></div>*/}

        </div>
        );
    } else {
        // Render a countdown
        return (
            <div className='text-center display-4 text-white fw-bolder' >
                <h3>Mint 2nd Batch in: {(days)} Days and</h3>
                <div className=''>{zeroPad(hours)} : {zeroPad(minutes)} : {zeroPad(seconds)}</div>

                <div className='mt-4 p-4 border-white border rounded-5' style={{backgroundColor: '#5c0ba1'}}>

                    <h3><b>Don't miss second collection batch!</b></h3>
                    <h4>Only 1874 Monkees left!</h4>

                    <h2 className='fw-bolder'>Join Now!</h2>

                    <div className="mt-4 d-flex justify-content-center">

                        <BtTwitter/>
                        <BtDiscord/>
                    </div>



                </div>
            </div>
        );
    }
};



function CountDown() {


    const startDate = moment(Date.now());
    const timeEnd = moment(1648152000000);
    const duration  = moment.duration(timeEnd.diff(startDate));


    return (
        // <div id='collection2' style={{backgroundColor: "#c2319d"}}>

            <Parallax className="container-fluid"
                      bgImage={require('../static/imgs/bg_am_g2.jpg')}
                      bgImageAlt="Mint BG"
                      strength={300}
                      id='collection2'
            >


            <div className='container'>


                <div className='d-flex justify-content-between align-items-xl-center align-items-end'>



                        <div className='col d-none d-md-flex align-items-center justify-content-center'>

                            <img className='img-fluid ' src={imgMainBanner} style={{maxHeight: 700}}/>

                        </div>


                    <div className='col text-white '>

                        <div className='text-center my-5 my-xl-0 '>

                            <h1 className='monkees'>2nd Collection</h1>

                            <Countdown
                                date={Date.now() + duration}
                                renderer={renderer}
                                zeroPadTime={2}
                            />


                        </div>
                    </div>


                    {/*<div className='col-6  p-4'>*/}

                    {/*</div>*/}

                    {/*<div className=' text-white my-4' >*/}
                    {/*    <div className='ms-5'>*/}
                    {/*    <div className='h4 text-white fw-bolder'>MINT!</div>*/}
                    {/*    <p>Thanks Everyone! <br />*/}
                    {/*    ArtMonkees First Collection sold out in only 4 minutes.  <br />*/}
                    {/*        You still can get your Monkee on our official secondary market partners </p>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className='d-flex text-white text-center p-4 d-flex justify-content-center align-items-center'>*/}
                    {/*    <a href="https://solanart.io/collections/artmonkees" target='_blank'><div className='me-0 me-lg-5 p-3 img-thumbnail'><img src={img_solanart} width={80} /></div></a>*/}
                    {/*    <a href="https://magiceden.io/marketplace/artmonkees"  target='_blank'><div className='me-0 me-lg-5 p-3 img-thumbnail'><img src={img_magiceden} width={80} /></div></a>*/}
                    {/*</div>*/}


                </div>



            </div>
            </Parallax>
        // </div>
    );
}

export default CountDown;
