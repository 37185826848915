import React, { useState,} from "react";
import { Parallax } from 'react-parallax';
import img_bg_space from '../static/imgs/bg_space.jpg';

function Story(props) {

    return (

        <Parallax className="container-fluid"
                  bgImage={require('../static/imgs/bg_space_eath.jpg')}
                  bgImageAlt="ArtMonkees"
                  strength={500}
        >

        <div id='story' className=" container py-5 text-white" >

            <h2 className='monkees text-center '>Story</h2>

            <div className='d-flex justify-content-center'>

                <div className='col-11 col-sm-10 col-lg-8 fs-4 border border-success mt-5 p-5 rounded-5 bg-black'>

                <p>Set in the year 2322 the collection depicts an important scene; Earth is a human-free planet. The humans left when the environment no longer seemed survivable and sought refuge in space stations orbiting Jupiter’s moons. Their absence caused the Earth to naturally regenerate itself, reversing the effects of human destruction and allowing life to thrive once more.</p>
                <p>One such life form is the ArtMonkees; a unique species of ape intent on not replicating human mistakes. They planned to do this by focusing on more important elements of life - like replacing FIAT money for cryptocurrencies. Their society is decentralized by their shared appreciation of nature and life, and they’re using the blockchain to do it.</p>

                </div>
            </div>
        </div>

        </Parallax>
    );
}

export default Story

