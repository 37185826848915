import React from "react";
import ImgDiscord from "../static/imgs/discord.svg";
import ImgTwitter from "../static/imgs/twitter.svg";


function BtDiscord() {


    function handleDiscord(e) {
        e.preventDefault();
        window.history.replaceState(null, "ArtMonkees", "#subscribe");
        window.open("https://discord.gg/g9CJJ5tk", "_blank");
    }



    return (

            <button className='btn btn-lg btn-dark px-4 ms-3 rounded-5 border-0' onClick={handleDiscord}
                    style={{backgroundColor: '#5f63d0'}}><img className='align-middle me-2' src={ImgDiscord}
                                                              height="30px"/> Discord
            </button>


    );

}

export default BtDiscord;
