import imgCBackground0 from '../static/imgs/traits/background/0.jpg'
import imgCBackground1 from '../static/imgs/traits/background/1.jpg'
import imgCBackground2 from '../static/imgs/traits/background/2.jpg'
import imgCBackground3 from '../static/imgs/traits/background/3.jpg'
import imgCBackground4 from '../static/imgs/traits/background/4.jpg'
import imgCBackground5 from '../static/imgs/traits/background/5.jpg'

import imgCBodyd0 from '../static/imgs/traits/body/0.jpg'
import imgCBodyd1 from '../static/imgs/traits/body/1.jpg'
import imgCBodyd2 from '../static/imgs/traits/body/2.jpg'
import imgCBodyd3 from '../static/imgs/traits/body/3.jpg'
import imgCBodyd4 from '../static/imgs/traits/body/4.jpg'
import imgCBodyd5 from '../static/imgs/traits/body/5.jpg'
import imgCBodyd6 from '../static/imgs/traits/body/6.jpg'

import imgCFurSkin0 from '../static/imgs/traits/furskin/0.jpg'
import imgCFurSkin1 from '../static/imgs/traits/furskin/1.jpg'
import imgCFurSkin2 from '../static/imgs/traits/furskin/2.jpg'
import imgCFurSkin3 from '../static/imgs/traits/furskin/3.jpg'
import imgCFurSkin4 from '../static/imgs/traits/furskin/4.gif'

import imgCBig0 from '../static/imgs/traits/big/0.jpg'
import imgCBig1 from '../static/imgs/traits/big/1.jpg'
import imgCBig2 from '../static/imgs/traits/big/2.jpg'
import imgCBig3 from '../static/imgs/traits/big/3.jpg'
import imgCBig4 from '../static/imgs/traits/big/4.jpg'

import imgCFurDesign0 from '../static/imgs/traits/furdesign/0.jpg'
import imgCFurDesign1 from '../static/imgs/traits/furdesign/1.jpg'
import imgCFurDesign2 from '../static/imgs/traits/furdesign/2.jpg'
import imgCFurDesign3 from '../static/imgs/traits/furdesign/3.jpg'
import imgCFurDesign4 from '../static/imgs/traits/furdesign/4.jpg'
import imgCFurDesign5 from '../static/imgs/traits/furdesign/5.jpg'
import imgCFurDesign6 from '../static/imgs/traits/furdesign/6.jpg'
import imgCFurDesign7 from '../static/imgs/traits/furdesign/7.jpg'
import imgCFurDesign8 from '../static/imgs/traits/furdesign/8.jpg'
import imgCFurDesign9 from '../static/imgs/traits/furdesign/9.jpg'

import imgCJewelry0 from '../static/imgs/traits/jewelry/0.jpg'
import imgCJewelry1 from '../static/imgs/traits/jewelry/1.jpg'
import imgCJewelry2 from '../static/imgs/traits/jewelry/2.jpg'
import imgCJewelry3 from '../static/imgs/traits/jewelry/3.jpg'
import imgCJewelry4 from '../static/imgs/traits/jewelry/4.jpg'
import imgCJewelry5 from '../static/imgs/traits/jewelry/5.jpg'
import imgCJewelry6 from '../static/imgs/traits/jewelry/6.jpg'


import imgCEyes0 from '../static/imgs/traits/eyes/0.jpg'
import imgCEyes1 from '../static/imgs/traits/eyes/1.jpg'
import imgCEyes2 from '../static/imgs/traits/eyes/2.jpg'
import imgCEyes3 from '../static/imgs/traits/eyes/3.jpg'
import imgCEyes4 from '../static/imgs/traits/eyes/4.jpg'
import imgCEyes5 from '../static/imgs/traits/eyes/5.jpg'
import imgCEyes6 from '../static/imgs/traits/eyes/6.jpg'
import imgCEyes7 from '../static/imgs/traits/eyes/7.jpg'
import imgCEyes8 from '../static/imgs/traits/eyes/8.jpg'
import imgCEyes9 from '../static/imgs/traits/eyes/9.jpg'
import imgCEyes10 from '../static/imgs/traits/eyes/10.jpg'
import imgCEyes11 from '../static/imgs/traits/eyes/11.jpg'
import imgCEyes12 from '../static/imgs/traits/eyes/12.jpg'
import imgCEyes13 from '../static/imgs/traits/eyes/13.jpg'
import imgCEyes14 from '../static/imgs/traits/eyes/14.jpg'
import imgCEyes15 from '../static/imgs/traits/eyes/15.jpg'
import imgCEyes16 from '../static/imgs/traits/eyes/16.jpg'
import imgCEyes17 from '../static/imgs/traits/eyes/17.jpg'
import imgCEyes18 from '../static/imgs/traits/eyes/18.jpg'
import imgCEyes19 from '../static/imgs/traits/eyes/19.jpg'

import imgCNose0 from '../static/imgs/traits/nose/0.jpg'
import imgCNose1 from '../static/imgs/traits/nose/1.jpg'
import imgCNose2 from '../static/imgs/traits/nose/2.jpg'
import imgCNose3 from '../static/imgs/traits/nose/3.jpg'
import imgCNose4 from '../static/imgs/traits/nose/4.jpg'
import imgCNose5 from '../static/imgs/traits/nose/5.jpg'
import imgCNose6 from '../static/imgs/traits/nose/6.jpg'
import imgCNose7 from '../static/imgs/traits/nose/7.jpg'
import imgCNose8 from '../static/imgs/traits/nose/8.jpg'
import imgCNose9 from '../static/imgs/traits/nose/9.jpg'
import imgCNose10 from '../static/imgs/traits/nose/10.jpg'
import imgCNose11 from '../static/imgs/traits/nose/11.jpg'
import imgCNose12 from '../static/imgs/traits/nose/12.jpg'
import imgCNose13 from '../static/imgs/traits/nose/13.jpg'
import imgCNose14 from '../static/imgs/traits/nose/14.jpg'
import imgCNose15 from '../static/imgs/traits/nose/15.jpg'
import imgCNose16 from '../static/imgs/traits/nose/16.jpg'
import imgCNose17 from '../static/imgs/traits/nose/17.jpg'
import imgCNose18 from '../static/imgs/traits/nose/18.jpg'

import imgCMouth0 from '../static/imgs/traits/mouth/0.jpg'
import imgCMouth1 from '../static/imgs/traits/mouth/1.jpg'
import imgCMouth2 from '../static/imgs/traits/mouth/2.jpg'
import imgCMouth3 from '../static/imgs/traits/mouth/3.jpg'
import imgCMouth4 from '../static/imgs/traits/mouth/4.jpg'
import imgCMouth5 from '../static/imgs/traits/mouth/5.jpg'
import imgCMouth6 from '../static/imgs/traits/mouth/6.jpg'
import imgCMouth7 from '../static/imgs/traits/mouth/7.jpg'
import imgCMouth8 from '../static/imgs/traits/mouth/8.jpg'
import imgCMouth9 from '../static/imgs/traits/mouth/9.jpg'
import imgCMouth10 from '../static/imgs/traits/mouth/10.jpg'
import imgCMouth11 from '../static/imgs/traits/mouth/11.jpg'
import imgCMouth12 from '../static/imgs/traits/mouth/12.jpg'
import imgCMouth13 from '../static/imgs/traits/mouth/13.jpg'

import imgCHead0 from '../static/imgs/traits/head/0.jpg'
import imgCHead1 from '../static/imgs/traits/head/1.jpg'
import imgCHead2 from '../static/imgs/traits/head/2.jpg'
import imgCHead3 from '../static/imgs/traits/head/3.jpg'
import imgCHead4 from '../static/imgs/traits/head/4.jpg'
import imgCHead5 from '../static/imgs/traits/head/5.jpg'
import imgCHead6 from '../static/imgs/traits/head/6.jpg'
import imgCHead7 from '../static/imgs/traits/head/7.jpg'
import imgCHead8 from '../static/imgs/traits/head/8.jpg'
import imgCHead9 from '../static/imgs/traits/head/9.jpg'
import imgCHead10 from '../static/imgs/traits/head/10.jpg'
import imgCHead11 from '../static/imgs/traits/head/11.jpg'
import imgCHead12 from '../static/imgs/traits/head/12.jpg'
import imgCHead13 from '../static/imgs/traits/head/13.jpg'
import imgCHead14 from '../static/imgs/traits/head/14.jpg'
import imgCHead15 from '../static/imgs/traits/head/15.jpg'
import imgCHead16 from '../static/imgs/traits/head/16.jpg'
import imgCHead17 from '../static/imgs/traits/head/17.jpg'
import imgCHead18 from '../static/imgs/traits/head/18.jpg'
import imgCHead19 from '../static/imgs/traits/head/19.jpg'
import imgCHead20 from '../static/imgs/traits/head/20.jpg'
import imgCHead21 from '../static/imgs/traits/head/21.jpg'
import imgCHead22 from '../static/imgs/traits/head/22.jpg'
import imgCHead23 from '../static/imgs/traits/head/23.jpg'
import imgCHead24 from '../static/imgs/traits/head/24.jpg'
import imgCHead25 from '../static/imgs/traits/head/25.jpg'
import imgCHead26 from '../static/imgs/traits/head/26.jpg'
import imgCHead27 from '../static/imgs/traits/head/27.jpg'

import imgCEyewear0 from '../static/imgs/traits/eyewear/0.jpg'
import imgCEyewear1 from '../static/imgs/traits/eyewear/1.jpg'
import imgCEyewear2 from '../static/imgs/traits/eyewear/2.jpg'
import imgCEyewear3 from '../static/imgs/traits/eyewear/3.jpg'
import imgCEyewear4 from '../static/imgs/traits/eyewear/4.jpg'
import imgCEyewear5 from '../static/imgs/traits/eyewear/5.jpg'
import imgCEyewear6 from '../static/imgs/traits/eyewear/6.jpg'

import imgCHand0 from '../static/imgs/traits/hand/0.jpg'
import imgCHand1 from '../static/imgs/traits/hand/1.jpg'
import imgCHand2 from '../static/imgs/traits/hand/2.jpg'
import imgCHand3 from '../static/imgs/traits/hand/3.jpg'
import imgCHand4 from '../static/imgs/traits/hand/4.jpg'
import imgCHand5 from '../static/imgs/traits/hand/5.jpg'
import imgCHand6 from '../static/imgs/traits/hand/6.jpg'
import imgCHand7 from '../static/imgs/traits/hand/7.jpg'
import imgCHand8 from '../static/imgs/traits/hand/8.jpg'
import imgCHand9 from '../static/imgs/traits/hand/9.jpg'
import imgCHand10 from '../static/imgs/traits/hand/10.jpg'
import imgCHand11 from '../static/imgs/traits/hand/11.jpg'
import imgCHand12 from '../static/imgs/traits/hand/12.jpg'
import imgCHand13 from '../static/imgs/traits/hand/13.jpg'

import imgCNone from '../static/imgs/traits/background/0.jpg'

const collection1 = {
    itens: [
        {
            name: 'Fur / Skin',
            pill: 'furskin',
            id: 1,
            traits: [
                {
                    id: '1',
                    title: 'Diamond',
                    img: imgCFurSkin0,
                    number: 139,
                },
                {
                    id: '2',
                    title: 'Zombie',
                    img: imgCFurSkin1,
                    number: 188,
                },
                {
                    id: '3',
                    title: 'Solana',
                    img: imgCFurSkin2,
                    number: 312,
                },
                {
                    id: '4',
                    title: 'Gold',
                    img: imgCFurSkin3,
                    number: 372,
                },
                {
                    id: '5',
                    title: 'Common',
                    img: imgCFurSkin4,
                    number: 3989,
                }
            ]
        },
        {
            name: 'Fur Design',
            pill: 'furdesign',
            id: 2,
            traits: [
                {
                    id: '1',
                    title: 'Mandrill',
                    img: imgCFurDesign0,
                    number: 695,
                },
                {
                    id: '2',
                    title: 'Bolt',
                    img: imgCFurDesign1,
                    number: 562,
                },
                {
                    id: '3',
                    title: 'Cryptofreak',
                    img: imgCFurDesign2,
                    number: 175,
                },
                {
                    id: '4',
                    title: 'Fluffy',
                    img: imgCFurDesign3,
                    number: 637,
                },
                {
                    id: '5',
                    title: 'Frizzy',
                    img: imgCFurDesign4,
                    number: 569,
                },
                {
                    id: '6',
                    title: 'Jaguar',
                    img: imgCFurDesign5,
                    number: 345,
                },
                {
                    id: '7',
                    title: 'Silverback',
                    img: imgCFurDesign6,
                    number: 670,
                },
                {
                    id: '8',
                    title: 'Rasta',
                    img: imgCFurDesign7,
                    number: 593,
                },
                {
                    id: '9',
                    title: 'Tiger',
                    img: imgCFurDesign8,
                    number: 272,
                },
                {
                    id: '10',
                    title: 'Tribal',
                    img: imgCFurDesign9,
                    number: 482,
                }
            ]
        },
        {
            name: 'Background',
            pill: 'background',
            id: 3,
            traits: [
                {
                    id: '1',
                    title: 'Gray',
                    img: imgCBackground0,
                    number: 971,
                },
                {
                    id: '2',
                    title: 'Red',
                    img: imgCBackground1,
                    number: 946,
                },
                {
                    id: '3',
                    title: 'Blue',
                    img: imgCBackground2,
                    number: 750,
                },
                {
                    id: '4',
                    title: 'Purple',
                    img: imgCBackground3,
                    number: 896,
                },
                {
                    id: '5',
                    title: 'Green',
                    img: imgCBackground4,
                    number: 662,
                },
                {
                    id: '6',
                    title: 'Yellow',
                    img: imgCBackground5,
                    number: 775,
                }
            ]
        },
        {
            name: 'Vehicles & Armors',
            pill: 'vehiclesarmors',
            id: 4,
            traits: [
                {
                    id: '1',
                    title: 'None',
                    img: imgCNone,
                    number: 4762,
                },
                {
                    id: '2',
                    title: 'King Armour',
                    img: imgCBig0,
                    number: 1,
                },
                {
                    id: '3',
                    title: 'Mecha',
                    img: imgCBig1,
                    number: 45,
                },
                {
                    id: '4',
                    title: 'UFO',
                    img: imgCBig2,
                    number: 58,
                },
                {
                    id: '5',
                    title: 'Rocket',
                    img: imgCBig3,
                    number: 63,
                },
                {
                    id: '6',
                    title: 'WarTank',
                    img: imgCBig4,
                    number: 71,
                }
            ]
        },
        {
            name: 'Eyes',
            pill: 'eyes',
            id: 5,
            traits: [
                {
                    id: '1',
                    title: 'BadBoy',
                    img: imgCEyes0,
                    number: 366,
                },
                {
                    id: '2',
                    title: 'Beholder',
                    img: imgCEyes1,
                    number: 137,
                },
                {
                    id: '3',
                    title: 'Blue Eyes',
                    img: imgCEyes2,
                    number: 379,
                },
                {
                    id: '4',
                    title: 'Cat',
                    img: imgCEyes3,
                    number: 306,
                },
                {
                    id: '5',
                    title: 'Crazy Eyes',
                    img: imgCEyes4,
                    number: 308,
                },
                {
                    id: '6',
                    title: 'Deadfish',
                    img: imgCEyes5,
                    number: 239,
                },
                {
                    id: '7',
                    title: 'Dope',
                    img: imgCEyes6,
                    number: 280,
                },
                {
                    id: '8',
                    title: 'Samurai',
                    img: imgCEyes7,
                    number: 248,
                },
                {
                    id: '9',
                    title: 'Grafitti',
                    img: imgCEyes8,
                    number: 167,
                },
                {
                    id: '10',
                    title: 'Kalamata',
                    img: imgCEyes9,
                    number: 219,
                },
                {
                    id: '11',
                    title: 'Kawaii',
                    img: imgCEyes10,
                    number: 266,
                },
                {
                    id: '12',
                    title: 'Laser Eyes',
                    img: imgCEyes11,
                    number: 254,
                },
                {
                    id: '13',
                    title: 'Mad',
                    img: imgCEyes12,
                    number: 366,
                },
                {
                    id: '14',
                    title: 'Octopus',
                    img: imgCEyes13,
                    number: 189,
                },
                {
                    id: '15',
                    title: 'Parrot',
                    img: imgCEyes14,
                    number: 245,
                },
                {
                    id: '16',
                    title: 'Silly',
                    img: imgCEyes15,
                    number: 319,
                },
                {
                    id: '17',
                    title: 'Slow Eyes',
                    img: imgCEyes16,
                    number: 290,
                },
                {
                    id: '18',
                    title: 'Spider',
                    img: imgCEyes17,
                    number: 89,
                },
                {
                    id: '19',
                    title: 'Stretching',
                    img: imgCEyes18,
                    number: 275,
                },
                {
                    id: '20',
                    title: 'Zombie',
                    img: imgCEyes19,
                    number: 58,
                }
            ]
        },
        {
            name: 'Nose',
            pill: 'nose',
            id: 6,
            traits: [
                {
                    id: '1',
                    title: 'Angry Gorilla',
                    img: imgCNose0,
                    number: 348,
                },
                {
                    id: '2',
                    title: 'Baboon',
                    img: imgCNose1,
                    number: 72,
                },
                {
                    id: '3',
                    title: 'Base',
                    img: imgCNose2,
                    number: 334,
                },
                {
                    id: '4',
                    title: 'Bored Gorilla',
                    img: imgCNose3,
                    number: 374,
                },
                {
                    id: '5',
                    title: 'Barroque',
                    img: imgCNose4,
                    number: 305,
                },
                {
                    id: '6',
                    title: 'Cat',
                    img: imgCNose5,
                    number: 163,
                },
                {
                    id: '7',
                    title: 'Chipmunk',
                    img: imgCNose6,
                    number: 267,
                },
                {
                    id: '8',
                    title: 'Funny',
                    img: imgCNose7,
                    number: 323,
                },
                {
                    id: '9',
                    title: 'Gorilla',
                    img: imgCNose8,
                    number: 142,
                },
                {
                    id: '10',
                    title: 'Mandrill',
                    img: imgCNose9,
                    number: 147,
                },
                {
                    id: '11',
                    title: 'Purple Ape',
                    img: imgCNose10,
                    number: 239,
                },
                {
                    id: '12',
                    title: 'Red Nose Ape',
                    img: imgCNose11,
                    number: 173,
                },
                {
                    id: '13',
                    title: 'Silly',
                    img: imgCNose12,
                    number: 362,
                },
                {
                    id: '14',
                    title: 'Sniffer',
                    img: imgCNose13,
                    number: 338,
                },
                {
                    id: '15',
                    title: 'Snow Monkee',
                    img: imgCNose14,
                    number: 380,
                },
                {
                    id: '16',
                    title: 'Vampire',
                    img: imgCNose15,
                    number: 161,
                },
                {
                    id: '17',
                    title: 'Wall Outlet',
                    img: imgCNose16,
                    number: 361,
                },
                {
                    id: '18',
                    title: 'Wide Nostril',
                    img: imgCNose17,
                    number: 262,
                },
                {
                    id: '19',
                    title: 'Zombie',
                    img: imgCNose18,
                    number: 249,
                }
            ]
        },
        {
            name: 'Mouth',
            pill: 'mouth',
            id: 7,
            traits: [
                {
                    id: '1',
                    title: 'Angry Mandrill',
                    img: imgCMouth0,
                    number: 303,
                },
                {
                    id: '2',
                    title: 'Baboon',
                    img: imgCMouth1,
                    number: 225,
                },
                {
                    id: '3',
                    title: 'Bored Gorilla',
                    img: imgCMouth2,
                    number: 573,
                },
                {
                    id: '4',
                    title: 'Bulldog',
                    img: imgCMouth3,
                    number: 311,
                },
                {
                    id: '5',
                    title: 'Clown Ape',
                    img: imgCMouth4,
                    number: 456,
                },
                {
                    id: '6',
                    title: 'Funny Ape',
                    img: imgCMouth5,
                    number: 356,
                },
                {
                    id: '7',
                    title: 'Gorilla',
                    img: imgCMouth6,
                    number: 226,
                },
                {
                    id: '8',
                    title: 'Happy Gold',
                    img: imgCMouth7,
                    number: 620,
                },
                {
                    id: '9',
                    title: 'Happy Tamarin',
                    img: imgCMouth8,
                    number: 341,
                },
                {
                    id: '10',
                    title: 'Lizard Mouth',
                    img: imgCMouth9,
                    number: 109,
                },
                {
                    id: '11',
                    title: 'Sick Baboon',
                    img: imgCMouth10,
                    number: 490,
                },
                {
                    id: '12',
                    title: 'Snow Monkee',
                    img: imgCMouth11,
                    number: 456,
                },
                {
                    id: '13',
                    title: 'Tamarin',
                    img: imgCMouth12,
                    number: 322,
                },
                {
                    id: '14',
                    title: 'Zombie',
                    img: imgCMouth13,
                    number: 212,
                }
            ]
        },
        {
            name: 'Head',
            pill: 'head',
            id: 8,
            traits: [
                {
                    id: '0',
                    title: 'None',
                    img: imgCNone,
                    number: 1688,
                },
                {
                    id: '1',
                    title: 'Crown',
                    img: imgCHead0,
                    number: 23,
                },
                {
                    id: '2',
                    title: 'Beanie',
                    img: imgCHead1,
                    number: 126,
                },
                {
                    id: '3',
                    title: 'Cap',
                    img: imgCHead2,
                    number: 124,
                },
                {
                    id: '4',
                    title: 'Mohawk Hair',
                    img: imgCHead3,
                    number: 120,
                },
                {
                    id: '5',
                    title: 'Saiyajin Hair',
                    img: imgCHead4,
                    number: 139,
                },
                {
                    id: '6',
                    title: 'Logan Hair',
                    img: imgCHead5,
                    number: 165,
                },
                {
                    id: '7',
                    title: 'Beard',
                    img: imgCHead6,
                    number: 189,
                },
                {
                    id: '8',
                    title: 'Mustache',
                    img: imgCHead7,
                    number: 92,
                },
                {
                    id: '9',
                    title: 'Lion Hair',
                    img: imgCHead8,
                    number: 127,
                },
                {
                    id: '10',
                    title: 'Cornrows Hair',
                    img: imgCHead9,
                    number: 171,
                },
                {
                    id: '11',
                    title: 'Headphone',
                    img: imgCHead10,
                    number: 135,
                },
                {
                    id: '12',
                    title: 'Spartan Helmet',
                    img: imgCHead11,
                    number: 67,
                },
                {
                    id: '13',
                    title: 'Astronaut Helmet',
                    img: imgCHead12,
                    number: 89,
                },
                {
                    id: '14',
                    title: 'Football Helmet',
                    img: imgCHead13,
                    number: 136,
                },
                {
                    id: '15',
                    title: 'Army Helmet',
                    img: imgCHead14,
                    number: 154,
                },
                {
                    id: '16',
                    title: 'Bike Helmet',
                    img: imgCHead15,
                    number: 122,
                },
                {
                    id: '17',
                    title: 'Bull Horn',
                    img: imgCHead16,
                    number: 127,
                },
                {
                    id: '18',
                    title: 'Deer Horn',
                    img: imgCHead17,
                    number: 87,
                },
                {
                    id: '19',
                    title: 'Goat Horn',
                    img: imgCHead18,
                    number: 66,
                },
                {
                    id: '20',
                    title: 'Hellboy Horn',
                    img: imgCHead19,
                    number: 119,
                },
                {
                    id: '21',
                    title: 'Reindeer Horn',
                    img: imgCHead20,
                    number: 137,
                },
                {
                    id: '22',
                    title: 'Unicorn Horn',
                    img: imgCHead21,
                    number: 23,
                },
                {
                    id: '23',
                    title: 'Jason Mask',
                    img: imgCHead22,
                    number: 102,
                },
                {
                    id: '24',
                    title: 'Angel Ring',
                    img: imgCHead23,
                    number: 126,
                },
                {
                    id: '25',
                    title: 'Afro Hair',
                    img: imgCHead24,
                    number: 110,
                },
                {
                    id: '26',
                    title: 'Samurai Helmet',
                    img: imgCHead25,
                    number: 115,
                },
                {
                    id: '27',
                    title: 'Gas Mask',
                    img: imgCHead26,
                    number: 135,
                },
                {
                    id: '28',
                    title: 'Knife',
                    img: imgCHead27,
                    number: 186,
                }
            ]
        },
        {
            name: 'Eyewear',
            pill: 'eyewear',
            id: 9,
            traits: [
                {
                    id: '0',
                    title: 'None',
                    img: imgCNone,
                    number: 3343,
                },
                {
                    id: '1',
                    title: 'Sunglasses',
                    img: imgCEyewear0,
                    number: 452,
                },
                {
                    id: '2',
                    title: 'Eye Patch',
                    img: imgCEyewear1,
                    number: 321,
                },
                {
                    id: '3',
                    title: 'Laser Eyes',
                    img: imgCEyewear2,
                    number: 70,
                },
                {
                    id: '4',
                    title: 'Rainbow Laser',
                    img: imgCEyewear3,
                    number: 134,
                },
                {
                    id: '5',
                    title: 'VR Headset',
                    img: imgCEyewear4,
                    number: 186,
                },
                {
                    id: '6',
                    title: 'Shutter Shades',
                    img: imgCEyewear5,
                    number: 224,
                },
                {
                    id: '7',
                    title: 'Deal With It',
                    img: imgCEyewear6,
                    number: 270,
                }
            ]
        },
        {
            name: 'Hand',
            pill: 'hand',
            id: 10,
            traits: [
                {
                    id: '0',
                    title: 'None',
                    img: imgCNone,
                    number: 2804,
                },
                {
                    id: '14',
                    title: 'Excaliburil',
                    img: imgCHand13,
                    number: 1,
                },
                {
                    id: '1',
                    title: 'Knife',
                    img: imgCHand0,
                    number: 73,
                },
                {
                    id: '2',
                    title: 'Samurai Sword',
                    img: imgCHand1,
                    number: 147,
                },
                {
                    id: '3',
                    title: 'LightSaber Jedi',
                    img: imgCHand2,
                    number: 88,
                },
                {
                    id: '4',
                    title: 'LightSaber Sith',
                    img: imgCHand3,
                    number: 28,
                },
                {
                    id: '5',
                    title: 'Baseball Bat',
                    img: imgCHand4,
                    number: 397,
                },
                {
                    id: '6',
                    title: 'Banana',
                    img: imgCHand5,
                    number: 544,
                },
                {
                    id: '7',
                    title: 'Apple Pencil',
                    img: imgCHand6,
                    number: 135,
                },
                {
                    id: '8',
                    title: 'Lucille',
                    img: imgCHand7,
                    number: 74,
                },
                {
                    id: '9',
                    title: 'Firecracker',
                    img: imgCHand8,
                    number: 237,
                },
                {
                    id: '10',
                    title: 'Machete',
                    img: imgCHand9,
                    number: 158,
                },
                {
                    id: '11',
                    title: 'Cleaver Knife',
                    img: imgCHand10,
                    number: 98,
                },
                {
                    id: '12',
                    title: 'Axe',
                    img: imgCHand11,
                    number: 157,
                },
                {
                    id: '13',
                    title: 'Claws',
                    img: imgCHand12,
                    number: 59,
                }
            ]
        },
        {
            name: 'Body',
            pill: 'body',
            id: 50,
            traits: [
                {
                    id: '1',
                    title: 'None',
                    img: imgCBackground0,
                    number: 2970,
                },
                {
                    id: '2',
                    title: 'Tail',
                    img: imgCBodyd0,
                    number: 629,
                },
                {
                    id: '3',
                    title: 'Feather Wings',
                    img: imgCBodyd1,
                    number: 340,
                },
                {
                    id: '4',
                    title: 'Bat Wings',
                    img: imgCBodyd2,
                    number: 226,
                },
                {
                    id: '5',
                    title: 'Mech Wing',
                    img: imgCBodyd3,
                    number: 340,
                },
                {
                    id: '6',
                    title: 'Arc Reactor',
                    img: imgCBodyd4,
                    number: 51,
                },
                {
                    id: '7',
                    title: 'Jetpack',
                    img: imgCBodyd5,
                    number: 289,
                },
                {
                    id: '8',
                    title: 'Cape',
                    img: imgCBodyd6,
                    number: 401,
                }
            ]
        },
        {
            name: 'Jewelry',
            pill: 'jewelry',
            id: 51,
            traits: [
                {
                    id: '1',
                    title: 'None',
                    img: imgCNone,
                    number: 3225,
                },
                {
                    id: '2',
                    title: 'Diamond Necklace',
                    img: imgCJewelry0,
                    number: 24,
                },
                {
                    id: '3',
                    title: 'Tooth Necklace',
                    img: imgCJewelry1,
                    number: 309,
                },
                {
                    id: '4',
                    title: 'Gold Necklace',
                    img: imgCJewelry2,
                    number: 241,
                },
                {
                    id: '5',
                    title: 'Bitcoin Necklace',
                    img: imgCJewelry3,
                    number: 156,
                },
                {
                    id: '6',
                    title: 'Solana Collar',
                    img: imgCJewelry4,
                    number: 92,
                },
                {
                    id: '7',
                    title: 'Earing',
                    img: imgCJewelry5,
                    number: 487,
                },
                {
                    id: '8',
                    title: 'Nipple Ring',
                    img: imgCJewelry6,
                    number: 466,
                }
            ]
        }
    ]
}
export default collection1
