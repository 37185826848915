import React, {useEffect, useState} from "react";
import imgMainBanner from '../static/imgs/artmonkees_timessquare.png';
import { useSpring, animated } from 'react-spring';
import { Parallax } from 'react-parallax';
import img_artmonkees from '../static/imgs/artmonkees_logo_morethananft_white.svg';
import Subscribe from "./Subscribe";
import BtTwitter from "./BtTwitter";
import BtDiscord from "./BtDiscord";
import video_timessquare from '../static/video/artmonkees_timessquare.mp4';

function MainBanner() {

    var video = {
        position: 'fixed',
        width: '100%'

    }

    return (

            <div>

                <header>

                    <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop">
                        <source src="https://artmonkees-files.s3.us-east-2.amazonaws.com/artmonkees_timessquare.mp4" type="video/mp4"/>
                    </video>
                    <div className="container h-100">
                        <div className="d-flex h-100 text-center align-items-center">
                            <div className="w-100 text-white">
                                <div className='row'>

                                    <div className='col-12 col-md-6 my-4 text-center'>

                                        <div className=''>
                                            <div className='mx-5'>
                                                <img className='img-fluid' src={img_artmonkees}/>
                                            </div>

                                            <h5 className='my-4 text-white'>Don't miss anything!</h5>
                                            <div className='d-flex justify-content-center'>
                                                <div className='col-11 col-md-10 '>
                                                    <Subscribe/>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-center">
                                                <BtTwitter/>
                                                <BtDiscord/>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='col col-md-4'>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </header>




            </div>

    );
}

export default MainBanner;
