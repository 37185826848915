import React, {useEffect, useState} from "react";
import imgMainBanner from '../static/imgs/armonkees.png';
import { useSpring, animated } from 'react-spring';
import { Parallax } from 'react-parallax';
import img_bg_space from '../static/imgs/armonkees_newyorktimes_01.jpg';


function ARMonkees() {


    return (
        <Parallax className="container-fluid"
                  bgImage={require('../static/imgs/armonkees_newyorktimes_01.jpg')}
                  bgImageAlt="MonkeeVerse BG"
                  strength={300}
        >

            <div id='ar' className='container'>
                <div className='row d-flex align-items-center justify-content-center px-3 mt-5 mt-md-0'>
                    <div className='col text-center text-white'>
                        <h1 className='monkees text-dark mb-4'>AR Monkees</h1>
                        <div className='text-center'>
                            <a className="btn btn-lg btn-success px-5 py-3" href='https://ar.artmonkees.io/' target='_blank'>Try Now!</a>
                        </div>
                    </div>

                    <div className='d-block d-md-none w-100' />

                    <div className='col d-flex align-items-center justify-content-end'>
                        <img className='img-fluid ' src={imgMainBanner} width={500}/>
                    </div>
                </div>
            </div>

        </Parallax>
    );
}

export default ARMonkees;
