import React from "react";
import Traits1 from "./containers/collection1/Traits1";
import MainBanner1 from "./containers/collection1/MainBanner1";
import SecondaryMarket1 from "./containers/collection1/SecondaryMarket1";
import Follow from "./containers/Follow";
import ARMonkees from "./containers/ARMonkees";
import Story from "./containers/Story";



function Collection1() {
    return (
        <div>

            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <a className="navbar-brand monkees fs-1" href="/">ArtMonkees</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon" />
                    </button>
                    <div className=" collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav mx-auto">

                            <li className="nav-item">
                                <a className="nav-link" href="/">Home</a>
                            </li>


                            <li className="nav-item">
                                <a className="nav-link" href="#traits">All Traits</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="#story">Story</a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="#ar">AR</a>
                            </li>


                        </ul>
                    </div>
                </div>
            </nav>

            <MainBanner1 />
            <SecondaryMarket1 />
            <Traits1 />
            <Story />
            <ARMonkees />

            <Follow />


        </div>
    );
}

export default Collection1;

