import React from "react";
import collection1 from "../../api/collection1";


function Collection1() {
    return (
        <div >

            <div id='traits' className='container'>


                <div className='my-5'>
                    <h2 className='monkees text-center text-white'>Expore All Traits</h2>
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">


                        { collection1.itens.map(item => (

                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${ item.id === 1 ? 'active' : '' }`}
                                        id="pills-dubi-tab"
                                        data-bs-toggle="pill"
                                        data-bs-target={`#pills-${item.pill}`}
                                        type="button"
                                        role="tab"
                                        aria-controls={`pills-${item.pill}`}
                                        aria-selected={`${ item.id === 1 ? 'true' : 'false' }`}>
                                    {item.name}
                                </button>
                            </li>

                        ))}

                    </ul>
                    <div className="tab-content" id="pills-tabContent">


                        {collection1.itens.map(item => (

                            <div className={`tab-pane fade ${item.id === 1 ? 'show active' : ''}`}
                                 id={`pills-${item.pill}`} role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 g-3">
                                    {item.traits.map(trait => (
                                        <div className="col">
                                            <div className="card text-white bg-dark h-100">
                                                <img src={trait.img} className="card-img-top" alt="..."/>
                                                <div className="card-body">
                                                    <h5 className="card-title">{trait.title}</h5>
                                                    <p className="card-text">Quantity: {trait.number}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        ))}

                    </div>

                </div>

            </div>

        </div>
    );
}

export default Collection1;

