import React from "react";
import Subscribe from "./Subscribe";
import BtDiscord from "./BtDiscord";
import BtTwitter from "./BtTwitter";

function Follow(props) {

    const context = {
        title: 'Join ArtMonkees!',
        subtitle: 'Make sure you don’t miss any intel on your mission, follow our socials and be ready to explore the universe of the kwoonies.',
    }


    return (


    <div id='team' className="bg-black container-fluid py-5" >

        <h2 className='monkees text-center text-white'  dangerouslySetInnerHTML={{ __html: context.title }} />

        <div className='container text-center text-white my-5 pb-5'>


            <div className='w-100'/>
            <div className='col d-flex justify-content-center '>
                <BtTwitter />
                <BtDiscord />
            </div>
            <div className='d-flex justify-content-center mt-5'>
                <div className='col col-sm-8 col-xl-4'>
                <Subscribe />
                </div>
            </div>
        </div>




    </div>


    );

}

export default Follow;
