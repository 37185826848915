import React, {useEffect, useState} from "react";
import imgMainBanner from '../static/imgs/gen2_gv_01.png';
import { useSpring, animated } from 'react-spring';
import { Parallax } from 'react-parallax';
import img_bg_space from '../static/imgs/bg_space.jpg';
import BtTwitter from "./BtTwitter";
import BtDiscord from "./BtDiscord";


function MintDate() {

    return (
            <div style={{backgroundColor: "#c2319d"}}>
            <div id='collection2' className='container' >

                <div className='row d-flex align-items-center justify-content-center '>


                    <div className='col d-none d-md-flex align-items-center justify-content-center'>

                        <img className='img-fluid ' src={imgMainBanner} style={{maxHeight: 500}}/>

                    </div>

                    <div className='col text-white '>

                        <div className='text-center my-5 my-xl-0 '>

                            <h1 className='monkees'>2nd Collection</h1>

                            <div className='p-4'>

                                <ul className="list-group list-group-flush ">
                                    <li className="list-group-item bg-transparent text-white h4">Collection of <b>5,000</b> ArtMonkees</li>
                                    <li className="list-group-item bg-transparent text-white h4">Mint date: <b>March</b> 2022 </li>
                                    {/*<li className="list-group-item bg-transparent text-white h4">Mint Price: <b>TBD</b></li>*/}
                                </ul>


                            </div>
                        </div>
                    </div>



                </div>




            </div>
            </div>


    );
}

export default MintDate;
