
import React from "react";
import { BrowserRouter, Routes ,Route } from 'react-router-dom';
import Home from './Home'
import Collection1 from "./Collection1";

function App() {

    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<Home/>} exact/>
                <Route path='/collection1' element={<Collection1/>} exact/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
