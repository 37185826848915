import React, {useEffect, useState} from "react";

function NavBar() {
    return (
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container">
                <a className="navbar-brand monkees fs-1" href="#">ArtMonkees</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                        aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className=" collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav mx-auto">

                        {/*<li className="nav-item">*/}
                        {/*    <a className="nav-link fw-bold" href="#collection2">2nd Collection</a>*/}
                        {/*</li>*/}


                        <li className="nav-item">
                            <a className="nav-link" href="/collection1">1st Collection</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="https://stake.artmonkees.io/" target='_blank'>Stake</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="https://sacrifice.artmonkees.io/" target='_blank'>Sacrifice</a>
                        </li>

                        
                        <li className="nav-item">
                            <a className="nav-link" href="https://ar.artmonkees.io/">AR</a>
                        </li>

                        <li className="nav-item">
                            <a className="nav-link" href="https://dashboard.artmonkees.io/">Island</a>
                        </li>


                        <li className="nav-item">
                            <a className="nav-link" href="#roadmap">Road Map</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#team">Team</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#faq">FAQ</a>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;
