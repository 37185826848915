import React, { useState,} from "react";
import { Waypoint } from 'react-waypoint';
import {animated, useSpring} from "react-spring";
const parse = require('html-react-parser');

function RoadMapItens(props) {

    const [inView, setInview] = useState(false);
    const transition = useSpring({
        to: {opacity: !inView ? 0 : 1},
        from: {opacity: 0},
        delay: 500,
    });


    return (

        <Waypoint onEnter={() => setInview(true)}>


        <animated.div style={transition} className=' d-flex justify-content-center'>

    <div key={props.context.id} className='col-11 col-sm-10 col-lg-8 border border-success text-white rounded-5 mt-5 p-5'>
        <h3>{props.context.title}</h3>

        <ul className="list-group list-group-flush ">

            { props.context.subitens.map(sub => (
                <li className="list-group-item bg-transparent text-white">  {parse(sub) } </li>
            ))}

        </ul>

    </div>
    </animated.div>
        </Waypoint>


    );
}

export default RoadMapItens;
