
import MainBanner from './containers/MainBanner'
import NavBar from './containers/NavBar'
import Traits2 from "./containers/Traits2";
import RoadMap from "./containers/RoadMap";
import Story from "./containers/Story";
import Team from "./containers/Team";
import Faq from "./containers/Faq";
import Follow from "./containers/Follow";
import MonkeeVerse from "./containers/MonkeeVerse";
import MintDate from "./containers/MintDate";
import {Parallax} from "react-parallax";
import CountDown from "./containers/CountDown";
import ARMonkees from "./containers/ARMonkees";
import Artists from "./containers/collection2/Artists";
import MainVideo from "./containers/MainVideo";
import SecondaryMarket from "./containers/SecondaryMarket";

import collection_imgs from "./api/collection";
import Collection from "./containers/Collection";
import Sacrifice from "./containers/Sacrifice";


function Home() {
    return (
        <div>
            <NavBar/>

            <MainVideo/>
            {/*<MainBanner />*/}

            {/*<CountDown/>*/}
            {/*<SecondaryMarket/>*/}
            {/*<MintDate />*/}

            <Story/>


            {/*<Traits2 />*/}
            <Sacrifice/>

            <MonkeeVerse/>

            <ARMonkees/>


            {/*<Collection props={collection_imgs} />*/}
            {/*<Artists/>*/}


            <RoadMap/>

            <Team/>
            <Faq/>
            <Follow/>
        </div>
    );
}

export default Home;
