import React, { useState,} from "react";
import RoadMapItens from "./RoadMapItens";
import roadmap from "../api/roadmap";

function RoadMap() {

    return (
        <div id='roadmap' className="container-fluid bg-dark py-5" >
            <div className='container'>

            <h2 className='monkees text-center text-white'>Road Map</h2>




                { roadmap.itens.map(item => (
                    <RoadMapItens key={item.id}  id={item.id} context={item}/>
                ))}
            </div>


        </div>
    );
}

export default RoadMap;
