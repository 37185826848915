
const roadmap = {
    itens: [
        {
            id: '1',
            title: '2022',
            subitens: [
                '<p class="text-decoration-line-through fw-lighter">Kick off - First Collection of 5000 ArtMonkees̶</p>',
                '<p class="text-decoration-line-through fw-lighter">First Collection - Revel</p>',
                '<p class="text-decoration-line-through fw-lighter">First Collection - Metaverse Collection Walk through</p>',
                '<p class="text-decoration-line-through fw-lighter">First Collection - ArtMonkees King Reavel</p>',
                '<p class="text-decoration-line-through fw-lighter">First Collection - Minting  Day</p>',
                '<p class="text-decoration-line-through fw-lighter">First Collection - Listing on Secondary Market</p>',
                '<p class="text-decoration-line-through fw-lighter">ArtMonkees AR Filter</p>',
                '<p class="text-decoration-line-through fw-lighter">Kick off - Second Collection (5000) (Famous Artist will collaborate in this new collection)</p>',
                '<p class="text-decoration-line-through fw-lighter">ArtMonkee King RealLife Toy First Collection - Delivery</p>',
                '<p class="text-decoration-line-through fw-lighter">Staking - Receive utility tokens: $SOLNANA</p>',
                '<p class="text-decoration-line-through fw-lighter">Raffles and Auctions for NFTs (this can be ArtMonkees or other NFTs the community wants)</p>',
                '<p class="text-decoration-line-through fw-lighter">BURN - ArtMonkees could be burned at the Monkeeverse volcano as a sacrifice to the MonkeeGods who in return, will reward you in MANY $SOLNANA for your sacrifice</p>',
                '<p class="text-decoration-line-through fw-lighter">OG AI Artmonkees Mint - Collection of AI Artmonkees only for OG Members</p>',
                '<p class="text-decoration-line-through fw-lighter">Relics Mint - Collection of Relics used to shape the MonkeeVerse Island</p>',
                "<p class='text-decoration-line-through fw-lighter'>Times Square - Times Square event to put Artmonkees in the spot of the world's most visited tourist attraction</p>",
            ]
        },
        {
            id: '2',
            title: 'Q1 2023',
            subitens: [
                '<p class=\'text-decoration-line-through fw-lighter\'>AR filter only for people who stake (gen1)</p>',
                '<p class=\'text-decoration-line-through fw-lighter\'>AR filter only for people who stake (gen2)</p>',
                '<p class=\'text-decoration-line-through fw-lighter\'>Opens access to your own personal Monkeeverse island</p>',
                "<p class=\'text-decoration-line-through fw-lighter\'>ArtMonkees Island - Enter each other friend's island to hangout</p>",
                "<p class=\'text-decoration-line-through fw-lighter\'>ArtMonkees Island - 1 island for every 10 ArtMonkees you have staked (except the first, so 1 artmonkee gets you 1 island, 11 gets you 2, 21 gets you 3, etc)</p>",
                'ArtMonkees Island - Shape your island using Relics',
                "NFT Collection of items - New NFT Collection of items (Yachts, Castles, Statues, Grills, Animals, etc) that you could only buy with $SOLNANAS to add to your island that you can trade and sell on the secondary market",
                "<p class=\'text-decoration-line-through fw-lighter\'>ArtMonkees Island - Upgrade your island</p>",
            ]
        }
    ]
}
export default roadmap
