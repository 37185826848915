import imgMonkeeTeam from "../static/imgs/artmonkees_team.png";
import img_team_holz from "../static/imgs/team/andre.jpg";
import img_team_dubiella from "../static/imgs/team/dubiella.png";
import img_team_balancer from "../static/imgs/team/balance.jpg";
import img_team_bini from "../static/imgs/team/bini.png";
import img_team_shartoshi from "../static/imgs/team/shartoshi.jpg";

const team = {
    itens: [
        {
            id: '1',
            title: 'Andre Holzmeister',
            img: img_team_holz,
            discription: 'Artist / Creator',
        },
        {
            id: '2',
            title: 'Balance',
            img: img_team_balancer,
            discription: 'Marketing Manager',
        },
        {
            id: '4',
            title: 'Bini',
            img: img_team_bini,
            discription: 'Developer / Metaverse / AR',
        },
        // {
        //     id: '5',
        //     title: 'Shartoshi',
        //     img: img_team_shartoshi,
        //     discription: 'Community Manager',
        // },
        {
            id: '6',
            title: 'Dubiella',
            img: img_team_dubiella,
            discription: 'Blockchain Developer / Producer',
        },
        {
            id: '7',
            title: 'Wat',
            img: imgMonkeeTeam,
            discription: 'Community Moderator',
        }
    ]
}
export default team
