import React, {useEffect, useState} from "react";
import imgMainBanner from '../../static/imgs/artmonkees_zombie_mainbanner.png';
import { useSpring, animated } from 'react-spring';
import { Parallax } from 'react-parallax';
import img_artmonkees from '../../static/imgs/artmonkees_logo_1collection_white.svg';


function MainBanner() {

    const transition = useSpring({
        to: {opacity: 1, x: '0px', position: 'relative'},
        from: {opacity: 0, x: '-50px',  position: 'relative'},
        delay: 200,
        duration: 10000
    });

    return (
        <Parallax className="container-fluid" style={{backgroundColor: '#41564c', }}
                  bgImage={require('../../static/imgs/bg_banner.jpg')}
                  bgImageAlt="ArtMonkees"
                  strength={-200}
        >


            <div className='d-flex flex-column-reverse flex-md-row  align-items-center justify-content-center'>



                <div className='col  d-flex justify-content-center'>
                    <img className='img-fluid mt-5 ps-0 ps-md-5' src={imgMainBanner} />
                </div>


                <div className=' col text-white mt-5 mt-md-0 pe-0 pe-md-5 d-flex align-items-center justify-content-center'>

                    <div className='col d-flex justify-content-center'>
                        <div className='col-10'>
                            <img className='img-fluid' src={img_artmonkees}/>
                        </div>
                    </div>


                    {/*<div className='d-none d-md-block m-4'>*/}
                    {/*    <Subscribe/>*/}
                    {/*</div>*/}



                </div>




            </div>


        </Parallax>
    );
}

export default MainBanner;
