import React, {useEffect, useState} from "react";
import imgMainBanner from '../static/imgs/ArtMonkees_MonkeeVerse_Sign.png';
import { useSpring, animated } from 'react-spring';
import { Parallax } from 'react-parallax';
import img_bg_space from '../static/imgs/monkeeverse_bg.jpg';


function MonkeeVerse() {


    return (
        <Parallax className="container-fluid"
                  bgImage={require('../static/imgs/monkeeverse_bg.jpg')}
                  bgImageAlt="MonkeeVerse BG"
                  strength={300}
        >

            <div id='monkeeverse' className='container'>

                <div className='row d-flex align-items-center justify-content-center px-3 mt-5 mt-md-0'>

                    <div className='text-center d-block d-md-none'>
                        <button className="btn btn-danger px-5 py-3"  disabled>ArtMonkees Island Not Available on Mobile</button>
                    </div>
                    <div className='col d-flex align-items-center justify-content-end'>


                        <img className='img-fluid ' src={imgMainBanner}/>

                    </div>

                    <div className='d-block d-md-none w-100' />


                    <div className='col '>
                        <div className='text-center d-none d-md-block'>
                            <a className="btn btn-lg btn-danger px-5 py-3" href='https://dashboard.artmonkees.io/' target='_blank'>Play Now!</a>
                        </div>

                    </div>

                </div>




            </div>

        </Parallax>
    );
}

export default MonkeeVerse;
