import React, { Component } from "react";

class Subscribe extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            sent: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({value: event.target.value});
    }


    handleSubmit(event) {
        event.preventDefault();


        console.log(this.state.name);
        console.log(this.state.email);

        fetch('https://api.sendinblue.com/v3/contacts', {
            method: 'POST',
            headers: {
                'accept': 'application/json',
                'api-key': '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',
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                "email": this.state.value,
                "updateEnabled": true,
                "listIds": [3]
            })
        }).then(success => {
            window.history.replaceState(null, "ArtMonkees", "#subscribe");
            this.setState({ sent: true });
        }).catch(error => {
            console.log(error);
        });




    }

    render() {
        const { sent } = this.state;
    return (
        <div className='mb-3 border border-danger rounded-5 p-2'>
            { !sent ? (
            <form className="" onSubmit={this.handleSubmit} id="form">
                <div className="input-group ">
                <input
                    type="email" className="form-control bg-transparent text-danger" placeholder="@"
                    aria-label="Enter your email address" aria-describedby="button-addon2"
                    value={this.state.value} onChange={this.handleChange}
                    required
                />
                <button type="submit" className='btn btn-outline-danger fw-bold'>NOTIFY ME</button>
                </div>
            </form>
                ) : (
                <div className='text-black text-uppercase fw-bold my-2'>
                    Thanks for Subscribe!
                </div>
            )}
        </div>
    )};

}


export default Subscribe;
